import { StaticPage } from './static-page.model'

export const pages: StaticPage[] = [
  {
    id: 'about',
    title: 'About',
    content: `
    #### 👋 Welcome to Dumpstermap.org, the collaborative world wide map to share dumpster locations for others to find!

    It is created for and by dumpster divers, freegans and others of such kind.
  
    Traditionally, most people who resort to dumpster diving are forced to do so out of economic necessity. The karung guni, the rag and bone man, waste picker, dumpster monkey, junk man or bin hoker are people who make their living by sorting and trading trash.
  
    Dumpster diving is looking for goods, most notably food, in dumpsters. In many Western countries it is possible to find perfectly good food, right in front of supermarkets, in backyards or in dumpsters. **If you would like to learn more about dumpster diving in general, have a look at [*trashwiki.org*](http://trashwiki.org)**.
    
    #### Some things to keep in mind:

    - Bring a bottle of water, to wash your hands when you dive into bags of fruits and vegetables
    - Always make sure you don't make a mess (i.e. open and close bags nicely)
    - Dumpster diving may be illegal in certain places, but enforcement is rare. In either case you may want to keep an eye out for police or unsympathetic property owners
`,
  } as StaticPage,
  {
    id: 'contact',
    title: 'Contact',
    content: `
    If you have any questions, feedback or ideas, send me a mail to *mail@mrtz.me*. Thanks!
    
    ### Impressum  
    
    Moritz Duchêne  
    Ramsbergstr. 19  
    86156 Augsburg `,
  } as StaticPage,
]
