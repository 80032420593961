import { Component, OnInit, ViewChild } from '@angular/core'
import { MatSidenav } from '@angular/material/sidenav'
import { SidebarService } from './shared/sidebar.service'
import { State } from './shared/state.service'
import { StaticPageStore } from './static-pages/static-page.store'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  @ViewChild('sidebar') sidebar: MatSidenav

  constructor(
    public staticPages: StaticPageStore,
    public sidebarService: SidebarService,
    private state: State
  ) {}

  ngOnInit(): void {
    this.state.routeChanged.subscribe(() => this.sidebarService.open())
  }
}
