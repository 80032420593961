import { Injectable } from '@angular/core'
import { Spot } from '../spots/spot.model'
import { filter, map, Observable, ReplaySubject } from 'rxjs'
import { Router, RoutesRecognized } from '@angular/router'
import { SpotService } from '../spots/spot.service'

@Injectable()
export class State {
  selectedSpot: ReplaySubject<Spot> = new ReplaySubject<Spot>()
  routeChanged: Observable<RoutesRecognized>

  constructor(private router: Router, private spotService: SpotService) {
    this.routeChanged = this.router.events
      .pipe(filter((event) => event instanceof RoutesRecognized))
      .pipe(map((event) => event as RoutesRecognized))

    this.bindStateToURLParams()
  }

  private bindStateToURLParams() {
    this.routeChanged.subscribe((route) => {
      if (route.url.includes('/detail')) {
        const id = route.state.root.firstChild.params['id']
        this.spotService
          .getSpot(id)
          .subscribe((spot) => this.selectedSpot.next(spot))
      }
    })
  }

  setSelectedSpot(spot: Spot) {
    this.router.navigate(['detail', spot.id])
  }
}
