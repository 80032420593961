import { Injectable } from '@angular/core'
import { BehaviorSubject, filter } from 'rxjs'

@Injectable()
export class SidebarService {
  private readonly _isOpen = new BehaviorSubject<boolean>(true)

  public readonly isOpen = this._isOpen.asObservable()
  public readonly onClose = this.isOpen.pipe(filter((value) => !value))
  public readonly onOpen = this.isOpen.pipe(filter((value) => value))
  constructor() {}

  open() {
    this._isOpen.next(true)
  }

  close() {
    this._isOpen.next(false)
  }
}
