<mat-card>
  <mat-card-content>
    <mat-form-field style="width: 100%">
      <input matInput type="text" [(ngModel)]="query" (change)="search()" placeholder="Search"/>
      <button mat-button *ngIf="query" matSuffix mat-icon-button aria-label="Clear" (click)="clear()">
        <mat-icon>close</mat-icon>
      </button>
      <button mat-button matSuffix mat-icon-button aria-label="Search" (click)="search()">
        <mat-icon>search</mat-icon>
      </button>
    </mat-form-field>

    <small *ngIf="searching">
      Searching . . .
    </small>

  </mat-card-content>
</mat-card>

<!--Search results-->
<mat-nav-list dense *ngIf="!searching">
  <mat-list-item *ngFor="let item of results" (click)="panTo(item)">
    <p mat-line>
      {{item.displayName}}
    </p>
    <p mat-line>
      <small>{{item.type | uppercase}}</small>
    </p>
  </mat-list-item>
</mat-nav-list>


