import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { SpotAddComponent } from './spot-add/spot-add.component'
import { SpotDetailComponent } from './spot-detail/spot-detail.component'
import { SharedModule } from '../shared/shared.module'
import { CommentsModule } from '../comments/comments.module'
import { ExtractEmojiPipe } from './emojis/emoji.pipe'
import { SpotService } from './spot.service'

@NgModule({
  declarations: [SpotAddComponent, SpotDetailComponent, ExtractEmojiPipe],
  imports: [CommonModule, SharedModule, CommentsModule],
  exports: [SpotAddComponent, SpotDetailComponent],
  providers: [SpotService],
})
export class SpotsModule {}
