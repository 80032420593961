import * as geojson from 'geojson'
import { LatLngBounds, Polygon } from 'leaflet'
import * as turf from '@turf/turf'

export class Area {
  private area: geojson.MultiPolygon | geojson.Polygon

  constructor() {
    this.area = { type: 'Polygon', coordinates: [] }
  }

  public extend(bounds: LatLngBounds) {
    let polygon = Area.boundsToPolygon(bounds)
    let union = turf.union(this.area, polygon) 
    if (union != null) {
      // If ``bounds`` is an empty polygon (with width=0 and height=0),
      // turf.union(...) might return null
      this.area = union.geometry
    }

  }

  public contains(bounds: LatLngBounds): boolean {
    let polygon = Area.boundsToPolygon(bounds)

    // Workaround for https://github.com/Turfjs/turf/issues/2337
    // Turf does not support MultiPolygons yet
    if (this.area.type == 'MultiPolygon') {
      return this.area.coordinates.some((value) =>
        turf.booleanContains({ type: 'Polygon', coordinates: value }, polygon)
      )
    }

    return turf.booleanContains(this.area, polygon)
  }

  public getGeometry(): geojson.MultiPolygon | geojson.Polygon {
    return this.area
  }

  public static boundsToPolygon(
    bounds: LatLngBounds
  ): geojson.Polygon | geojson.MultiPolygon {
    return new Polygon([
      bounds.getNorthWest(),
      bounds.getNorthEast(),
      bounds.getSouthEast(),
      bounds.getSouthWest(),
    ]).toGeoJSON().geometry
  }
}
