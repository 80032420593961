import * as geojson from 'geojson'
import { LatLng } from 'leaflet'

export type VotingValues = 'good' | 'average' | 'senseless'

class SpotComment {
  id?: number
  value: VotingValues
  comment: string
  // eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
  created_date?: string
  name?: string
}

class SpotProperties implements geojson.GeoJsonProperties {
  good?: number
  bad?: number
  rating?: number
  name: string
  created?: string
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
  voting_set: SpotComment[]
}

export class Spot implements geojson.Feature<geojson.Point, SpotProperties> {
  id?: number
  geometry: geojson.Point
  properties: SpotProperties
  type: 'Feature'

  public static create(
    coords: LatLng,
    title: string,
    rating: VotingValues,
    commentMessage: string
  ): Spot {
    return {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [coords.lng, coords.lat],
      },
      properties: {
        name: title,
        voting_set: [
          {
            value: rating,
            comment: commentMessage,
          },
        ],
      },
    }
  }
}

export class SpotCollection
  implements geojson.FeatureCollection<geojson.Point, SpotProperties>
{
  features: Spot[]
  type: 'FeatureCollection'
}
