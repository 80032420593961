<mat-card id="spot-card" *ngIf="spot">
  <mat-card-header>
    <mat-card-title>{{ spot.properties.name }}</mat-card-title>
    <mat-card-subtitle>
      {{ emojis }}
    </mat-card-subtitle>
    <mat-card-subtitle>
      Added {{ spot.properties.created | date }}
    </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <mat-divider></mat-divider>

    <app-spot-detail-comment
      *ngFor="let comment of spot.properties.voting_set"
      [name]="comment.name"
      [date]="comment.created_date"
      [message]="comment.comment"
    ></app-spot-detail-comment>

    <mat-divider></mat-divider>
    <br>
    <router-outlet></router-outlet>

  </mat-card-content>
  <mat-card-actions>
    <button mat-raised-button color="accent" style="width: 100%" [routerLink]="['comment']" *ngIf="showAddComment">
      Add comment
    </button>
  </mat-card-actions>
</mat-card>
