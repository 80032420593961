import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { PhotonService } from './photon/photon.service'
import { GeocodingService } from './geocoding.service'

@NgModule({
  imports: [CommonModule],
  declarations: [],
  providers: [{ provide: GeocodingService, useClass: PhotonService }],
})
export class GeocodingModule {}
