import { Icon, Marker } from 'leaflet'
import { Spot } from '../../spots/spot.model'
import { coordsToLatLng } from '../utils'

export class SpotMarker extends Marker {
  constructor(spot: Spot) {
    super(coordsToLatLng(spot.geometry.coordinates))
    this.setIcon(this.getDefaultIcon())
  }

  highlight() {
    this.setIcon(this.getHighlightedIcon())
  }

  unHighlight() {
    this.setIcon(this.getDefaultIcon())
  }

  getDefaultIcon() {
    return new Icon({
      iconSize: [35, 35],
      iconUrl: 'assets/marker.png',
    })
  }

  getHighlightedIcon() {
    return new Icon({
      iconSize: [55, 55],
      iconUrl: 'assets/marker_highlighted.png',
    })
  }
}
