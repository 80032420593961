import { BrowserModule } from '@angular/platform-browser'
import { NgModule } from '@angular/core'

import { AppComponent } from './app.component'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { RoutingModule } from './routing.module'
import { StaticPagesModule } from './static-pages/static-pages.module'
import { MarkdownModule } from 'ngx-markdown'
import { SharedModule } from './shared/shared.module'
import { MapModule } from './map/map.module'
import { GeocodingModule } from './geocoding/geocoding.module'
import { SearchModule } from './search/search.module'
import { SpotsModule } from './spots/spots.module'

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MarkdownModule.forRoot(),
    RoutingModule,
    SharedModule,
    MapModule,
    SpotsModule,
    StaticPagesModule,
    GeocodingModule,
    SearchModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
