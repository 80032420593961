import { Injectable } from '@angular/core'
import { GeocodingService } from '../geocoding.service'
import { LatLng } from '../../../geo/latlng'
import { Observable } from 'rxjs'
import { GeocodingResult } from '../geocoding.result'
import { HttpClient } from '@angular/common/http'
import { map } from 'rxjs/operators'
import { PhotonResponse } from './photon.response'

@Injectable({
  providedIn: 'root',
})
export class PhotonService implements GeocodingService {
  API_URL = 'https://photon.komoot.io'

  constructor(private http: HttpClient) {}

  reverse(latLng: LatLng): Observable<GeocodingResult> {
    let url = `${this.API_URL}/reverse?lon=${latLng.lng}&lat=${latLng.lat}`
    return this.http
      .get<PhotonResponse>(url)
      .pipe(map(this.toGeocodingResults))
      .pipe(map((results) => results[0]))
  }

  search(query: string): Observable<GeocodingResult[]> {
    let url = this.API_URL + '/api/?q=' + query
    return this.http.get<PhotonResponse>(url).pipe(map(this.toGeocodingResults))
  }

  private toGeocodingResults(response: PhotonResponse): GeocodingResult[] {
    return response.features.map<GeocodingResult>((feature) => ({
      displayName: [
        feature.properties.name,
        feature.properties.street,
        feature.properties.city,
        feature.properties.country,
      ]
        .filter((value) => !!value)
        .join(', '),
      type: feature.properties.type,
      latLng: LatLng.getCenterOf(feature.geometry),
    }))
  }
}
