import { Component, OnInit } from '@angular/core'
import { StaticPage } from '../../static-page.model'
import { StaticPageStore } from '../../static-page.store'
import { ActivatedRoute } from '@angular/router'

@Component({
  selector: 'app-static-page',
  templateUrl: './static-page.component.html',
  styleUrls: ['./static-page.component.css'],
})
export class StaticPageComponent implements OnInit {
  page: StaticPage

  constructor(
    private route: ActivatedRoute,
    private pageStore: StaticPageStore
  ) {}

  ngOnInit() {
    this.route.paramMap.subscribe((params) => this.loadPage(params.get('id')))
  }

  loadPage(id: string) {
    this.pageStore.getPage(id).subscribe((page) => (this.page = page))
  }
}
