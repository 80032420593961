import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { SpotDetailCommentComponent } from './spot-detail-comment/spot-detail-comment.component'
import { CommentAddComponent } from './comment-add/comment-add.component'
import { SharedModule } from '../shared/shared.module'
import { CommentService } from './comment.service'

@NgModule({
  declarations: [SpotDetailCommentComponent, CommentAddComponent],
  imports: [CommonModule, SharedModule],
  exports: [SpotDetailCommentComponent, CommentAddComponent],
  providers: [CommentService],
})
export class CommentsModule {}
