import * as L from 'leaflet'
import { Subject } from 'rxjs'
import { Spot } from '../../spots/spot.model'
import { SpotMarker } from './spotMarker'

export class ClusteredSpotLayer {
  onSpotSelected: Subject<Spot> = new Subject<Spot>()
  private highlightedSpot: Spot

  private layer: L.MarkerClusterGroup = new L.MarkerClusterGroup({
    removeOutsideVisibleBounds: true,
  })

  private spotMarkersOnMap = new Map<number, SpotMarker>()

  public addTo(map: L.Map) {
    this.layer.addTo(map)
  }

  public addSpots(spots: Spot[]) {
    spots.forEach((spot) => {
      if (!this.isAlreadyOnMap(spot)) {
        this.addSpotToMap(spot)
      }
    })
  }

  public addSpot(spot: Spot) {
    if (!this.isAlreadyOnMap(spot)) {
      this.addSpotToMap(spot)
    }
  }

  public isAlreadyOnMap(spot: Spot): boolean {
    return new Set(this.spotMarkersOnMap.keys()).has(spot.id)
  }

  private addSpotToMap(spot: Spot): SpotMarker {
    const layer = new SpotMarker(spot)
    this.layer.addLayer(layer)
    layer.on({
      click: (event) => {
        this.onSpotSelected.next(spot)
        this.highlightSpot(spot)
      },
    })

    this.spotMarkersOnMap.set(spot.id, layer)

    return layer
  }

  highlightSpot(spot: Spot) {
    if (this.highlightedSpot) {
      this.spotMarkersOnMap.get(this.highlightedSpot.id).unHighlight()
    }
    this.spotMarkersOnMap.get(spot.id).highlight()
    this.highlightedSpot = spot
  }
}
