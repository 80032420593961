import { Injectable } from '@angular/core'
import { map, Observable, of, timeout } from 'rxjs'
import { LatLng } from 'src/geo/latlng'
import { GeoLocationService } from '../geolocation/geolocation.service'
import { MapConfiguration } from './configuration.interface'

const BERLIN = new LatLng(52.520008, 13.404954)
const INITIAL_CONFIG = {
  baseLayer: 'https://tile.openstreetmap.org/{z}/{x}/{y}.png',
  zoom: 11,
  maxZoom: 20,
}

@Injectable()
export class MapConfigurationService {
  constructor(private geolocation: GeoLocationService) {}

  public getConfig(): Observable<MapConfiguration> {
    return this.getMapCenter().pipe(
      map((location) => {
        return {
          center: location,
          ...INITIAL_CONFIG,
        }
      })
    )
  }

  private getMapCenter(): Observable<LatLng> {
    // Returns the user's location, if it can be determined within 500ms.
    // If not, we'll use BERLIN as a fallback value.
    return this.geolocation
      .getLocation()
      .pipe(timeout({ each: 500, with: () => of(BERLIN) }))
  }
}
