import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { StaticPageComponent } from './components/static-page/static-page.component'
import { UIModule } from '../shared/ui.module'
import { RouterModule } from '@angular/router'
import { StaticPageStore } from './static-page.store'
import { MarkdownModule } from 'ngx-markdown'

@NgModule({
  imports: [CommonModule, RouterModule, UIModule, MarkdownModule.forChild()],
  providers: [StaticPageStore],
  declarations: [StaticPageComponent],
  exports: [StaticPageComponent],
})
export class StaticPagesModule {}
