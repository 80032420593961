<div
  *ngIf="options"
  id="map"
  #map
  leaflet
  [leafletOptions]="options"
  (leafletMapReady)="onMapReady($event)"
  (leafletMapMoveEnd)="onMapMoveEnd($event)"
  (leafletMapZoomEnd)="onMapZoomEnd($event)"
></div>
