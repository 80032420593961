import { Component } from '@angular/core'
import { Location } from '@angular/common'
import { CommentService } from '../comment.service'
import { Spot, VotingValues } from '../../spots/spot.model'
import { State } from '../../shared/state.service'
import { Router } from '@angular/router'

@Component({
  selector: 'app-comment-add',
  templateUrl: './comment-add.component.html',
  styleUrls: ['./comment-add.component.scss'],
})
export class CommentAddComponent {
  rating: VotingValues
  comment: string
  spot: Spot

  constructor(
    private location: Location,
    private commentService: CommentService,
    private state: State,
    private router: Router
  ) {
    this.state.selectedSpot.subscribe((spot) => (this.spot = spot))
  }

  submit() {
    this.commentService
      .create({
        dumpster: this.spot.id,
        value: this.rating,
        comment: this.comment,
      })
      .subscribe((result) => this.router.navigate(['detail', this.spot.id]))
  }

  cancel() {
    this.location.back()
  }
}
