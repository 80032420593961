import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { SearchComponent } from './search-sidebar/search.component'
import { GeocodingModule } from '../geocoding/geocoding.module'
import { SharedModule } from '../shared/shared.module'

@NgModule({
  imports: [CommonModule, SharedModule, GeocodingModule],
  declarations: [SearchComponent],
  providers: [],
  exports: [],
})
export class SearchModule {}
