import { ApplicationRef, Component, NgZone, OnInit } from '@angular/core'
import { LeafletEvent, Map, MapOptions, TileLayer } from 'leaflet'
import { SpotService } from '../../spots/spot.service'
import { State } from '../../shared/state.service'
import { ClusteredSpotLayer } from './clusteredSpotLayer'
import { Spot } from '../../spots/spot.model'
import { SidebarService } from '../../shared/sidebar.service'
import { Area } from '../../../geo/area'
import { MapService } from '../map.service'
import { MapConfigurationService } from '../configuration.service'
import { LatLng } from '../../../geo/latlng'
import { GeoLocationService } from 'src/app/geolocation/geolocation.service'

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit {
  map: Map
  options: MapOptions
  spotLayer: ClusteredSpotLayer = new ClusteredSpotLayer()

  private areaFetched: Area = new Area()

  constructor(
    private spots: SpotService,
    private state: State,
    private applicationRef: ApplicationRef,
    private ngZone: NgZone,
    private sidebar: SidebarService,
    private mapService: MapService,
    private configProvider: MapConfigurationService,
  ) {
    this.configureMap()
  }

  private configureMap() {
    this.configProvider.getConfig().subscribe((config) => {
      const baseLayer = new TileLayer(config.baseLayer, {
        maxZoom: config.maxZoom,
        attribution: '...',
      })
      this.options = {
        layers: [baseLayer],
        zoom: config.zoom,
        center: config.center,
        zoomControl: false
      }
    })
  }

  onMapReady(map: Map) {
    this.map = map
    this.mapService.registerMap(map)
    this.spotLayer.addTo(this.map)
    this.retrieveSpots()
  }

  onMapMoveEnd(event: LeafletEvent) {
    this.retrieveSpots()
  }

  onMapZoomEnd(event: LeafletEvent) {
    this.retrieveSpots()
  }

  retrieveSpots() {
    if (this.map.getZoom() < 8){
      return
    }
    let bounds = this.map.getBounds()

    if (!this.areaFetched.contains(bounds)) {
      this.areaFetched.extend(bounds)

      this.spots.getSpots(bounds).subscribe((spots) => {
        this.spotLayer.addSpots(spots)
      })
    }
  }

  onSpotSelected(spot: Spot) {
    if (!this.spotLayer.isAlreadyOnMap(spot)) {
      this.spotLayer.addSpot(spot)
    }
    this.spotLayer.highlightSpot(spot)
    this.sidebar.open()
  }

  ngOnInit(): void {
    this.state.selectedSpot.subscribe((spot) => this.onSpotSelected(spot))

    this.spotLayer.onSpotSelected.subscribe((spot) => {
      // Must be run within the Angular zone, so that Subscribers of `selectedSpot` can
      // trigger the Navigation.
      this.ngZone.run(() => {
        this.state.setSelectedSpot(spot)
        // Manually run change detection.
        // See https://stackoverflow.com/questions/34827334/triggering-change-detection-manually-in-angular.
        this.applicationRef.tick()
      })
    })
  }
}
