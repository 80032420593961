import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MapService } from './map.service'
import { MapComponent } from './map-component/map.component'
import { SharedModule } from '../shared/shared.module'
import { LeafletModule } from '@asymmetrik/ngx-leaflet'
import { LeafletMarkerClusterModule } from '@asymmetrik/ngx-leaflet-markercluster'
import { MapConfigurationService } from './configuration.service'
import { GeolocationModule } from '../geolocation/geolocation.module'

@NgModule({
  declarations: [MapComponent],
  imports: [
    CommonModule,
    SharedModule,
    LeafletModule,
    LeafletMarkerClusterModule,
    GeolocationModule
  ],
  exports: [MapComponent],
  providers: [MapService, MapConfigurationService],
})
export class MapModule {}
