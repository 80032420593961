import { StaticPage } from './static-page.model'
import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable, of } from 'rxjs'
import { pages } from './pages'

@Injectable()
export class StaticPageStore {
  private _pages = new BehaviorSubject<StaticPage[]>([])
  public readonly pages: Observable<StaticPage[]> = this._pages.asObservable()

  constructor() {
    this.loadInitialData()
  }

  getPage(id: string): Observable<StaticPage> {
    const page: StaticPage = this._pages.getValue().find((p) => p.id === id)
    return of(page)
  }

  private loadInitialData() {
    this._pages.next(pages)
  }
}
