<mat-card *ngIf="page">
  <mat-card-header>
    <mat-card-title>
      <h2>{{ page.title }}</h2>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <markdown [data]="page.content"></markdown>
  </mat-card-content>
</mat-card>
