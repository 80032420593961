import { Component, Input, OnInit } from '@angular/core'

/**
 * Maps the input string to an integer value
 */
function mapStringToInt(s: string): number {
  let id = 0
  for (const c of s) {
    id += c.charCodeAt(0)
  }
  return id
}

function getGravatarURL(id: string) {
  return `http://www.gravatar.com/avatar/${mapStringToInt(
    id
  )}?s=60&default=robohash&forcedefault=y`
}

@Component({
  selector: 'app-spot-detail-comment',
  templateUrl: './spot-detail-comment.component.html',
  styleUrls: ['./spot-detail-comment.component.scss'],
})
export class SpotDetailCommentComponent implements OnInit {
  @Input() name: any
  @Input() date: string
  @Input() message: string

  gravatarURL: string

  ngOnInit(): void {
    this.gravatarURL = getGravatarURL(this.date)
  }
}
