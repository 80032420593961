import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { UIModule } from './ui.module'
import { FormsModule } from '@angular/forms'
import { RoutingModule } from '../routing.module'
import { SidebarService } from './sidebar.service'
import { State } from './state.service'
import { HttpClientModule } from '@angular/common/http'

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    UIModule,
    FormsModule,
    RoutingModule,
    HttpClientModule,
  ],
  exports: [UIModule, FormsModule, RoutingModule],
  providers: [SidebarService, State],
})
export class SharedModule {}
