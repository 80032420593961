import { Observable } from 'rxjs'
import { GeocodingResult } from './geocoding.result'
import { LatLng } from 'leaflet'

export abstract class GeocodingService {
  /***
   * Generates an address from a latitude and longitude (reverse geocoding)
   */
  public abstract reverse(latLng: LatLng): Observable<GeocodingResult>

  /***
   * Converts location text into geographic coordinates (forward geocoding)
   */
  public abstract search(query: string): Observable<GeocodingResult[]>
}
