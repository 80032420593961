import { Injectable } from '@angular/core'
import { LatLng, Layer, Map } from 'leaflet'

/**
 * Provides (controlled) access to the map to external components.
 */
@Injectable()
export class MapService {
  private map: Map

  constructor() {}

  public registerMap(map: Map) {
    this.map = map
  }

  public addLayer(layer: Layer) {
    this.map.addLayer(layer)
  }

  public removeLayer(layer: Layer) {
    this.map.removeLayer(layer)
  }

  public getCenter(): LatLng {
    return this.map.getCenter()
  }

  public panTo(latLng: LatLng) {
    this.map.panTo(latLng)
  }

  public setZoom(zoom: number) {
    this.map.setZoom(zoom)
  }
}
