<mat-card id="spot-card">
  <mat-card-header>
    <mat-card-title>Add new location</mat-card-title>
    <mat-card-subtitle>{{ comment | extractemojis }}</mat-card-subtitle>
    <mat-card-subtitle *ngIf="adress">
      {{ adress }}</mat-card-subtitle
    >
  </mat-card-header>
  <mat-card-content>
    <form (ngSubmit)="submit()">
      <mat-form-field appearance="outline" style="width: 100%">
        <mat-label>Title</mat-label>
        <input
          matInput
          placeholder="E.g. ALDI, Carrefour, SPAR, ..."
          [(ngModel)]="title"
          name="title"
          required
        />
      </mat-form-field>
      <mat-form-field appearance="outline" style="width: 100%">
        <mat-label>Rating</mat-label>
        <mat-select value="good" [(ngModel)]="rating" name="rating" required>
          <mat-option value="good"> Good</mat-option>
          <mat-option value="average"> Average</mat-option>
          <mat-option value="senseless"> Senseless</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" style="width: 100%">
        <mat-label>Leave a comment</mat-label>
        <mat-hint align="begin"
          >Describe the location, where to find the dumpsters, how to open them,
          etc...
        </mat-hint>
        <textarea
          matInput
          [(ngModel)]="comment"
          name="comment"
          placeholder=""
          required
        ></textarea>
      </mat-form-field>
      <div style="margin-top: 30px"></div>
      <button mat-raised-button color="accent" type="submit" style="width: 50%">
        Submit
      </button>
      <button
        mat-raised-button
        (click)="cancel()"
        style="width: 45%; margin-left: 5px"
      >
        Cancel
      </button>
    </form>
  </mat-card-content>
  <mat-card-actions></mat-card-actions>
</mat-card>
