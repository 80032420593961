import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { GeoLocationService } from './geolocation.service'
import { IPAPIGeolocationService } from './ipapi/ipapi.service'

@NgModule({
  imports: [CommonModule],
  declarations: [],
  providers: [
    { provide: GeoLocationService, useClass: IPAPIGeolocationService },
  ],
})
export class GeolocationModule {}
