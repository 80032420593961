import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { LatLngBounds } from 'leaflet'
import { Spot, SpotCollection } from './spot.model'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { DUMPSTER_API } from '../../config'

export class SpotEndpoints {
  public static CREATE = `${DUMPSTER_API}/dumpsters/`
  public static DETAIL = (id: number) => `${DUMPSTER_API}/dumpsters/${id}`

  public static WITHIN_BOUNDS(bounds: LatLngBounds): string {
    const north = bounds.getNorth()
    const east = bounds.getEast()
    const south = bounds.getSouth()
    const west = bounds.getWest()
    return `${DUMPSTER_API}/dumpsters/withinbounds/${west}/${south}/${east}/${north}`
  }
}

@Injectable()
export class SpotService {
  constructor(private http: HttpClient) {}

  getSpotsAsGeoJSONCollection(
    bounds: LatLngBounds
  ): Observable<SpotCollection> {
    return this.http.get<SpotCollection>(SpotEndpoints.WITHIN_BOUNDS(bounds))
  }

  getSpots(bounds: LatLngBounds): Observable<Spot[]> {
    const obs = this.getSpotsAsGeoJSONCollection(bounds)
    return obs.pipe(map((collection) => collection.features as Spot[]))
  }

  getSpot(id: number): Observable<Spot> {
    return this.http.get<Spot>(SpotEndpoints.DETAIL(id))
  }

  create(spot: Spot): Observable<Spot> {
    return this.http.post<Spot>(SpotEndpoints.CREATE, spot)
  }
}
