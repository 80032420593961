import { Component } from '@angular/core'
import { GeocodingResult } from '../../geocoding/geocoding.result'
import { MapService } from '../../map/map.service'
import { GeocodingService } from '../../geocoding/geocoding.service'

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css'],
})
export class SearchComponent {
  query: string
  results: GeocodingResult[] = []

  searching = false

  constructor(private geocoding: GeocodingService, private map: MapService) {}

  search() {
    this.searching = true
    this.geocoding.search(this.query).subscribe(
      (results) => {
        this.searching = false
        if (results.length > 0) {
          this.results = results
          this.panTo(results[0])
        }
      },
      (error) => (this.searching = false)
    )
  }

  clear() {
    this.query = ''
    this.results = []
    this.searching = false
  }

  /**
   * Pans the map to the coordinates of the given item
   */
  panTo(item: GeocodingResult) {
    this.map.panTo(item.latLng)
    this.map.setZoom(12)
  }
}
