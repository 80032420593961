import { LatLng as LeafletLatLng } from 'leaflet'
import { Geometry, Point } from 'geojson'

export class LatLng extends LeafletLatLng {
  public static fromPoint(point: Point) {
    return new LatLng(point.coordinates[1], point.coordinates[0])
  }

  public static getCenterOf(geometry: Geometry) {
    switch (geometry.type) {
      case 'Point':
        return LatLng.fromPoint(geometry)
      default:
        throw Error('Not Implemented.')
    }
  }
}
