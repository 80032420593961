<h2>Add comment</h2>
<form (ngSubmit)="submit()">
    <mat-form-field appearance="outline" style="width: 100%">
        <mat-label>Rating</mat-label>
        <mat-select value="good" [(ngModel)]="rating" name="rating" required>
            <mat-option value="good"> Good</mat-option>
            <mat-option value="average"> Average</mat-option>
            <mat-option value="senseless"> Senseless</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline" style="width: 100%">
        <mat-label>Leave a comment</mat-label>
        <mat-hint align="begin"
        >Describe the location, where to find the dumpsters, how to open them,
            etc...
        </mat-hint>
        <textarea
                matInput
                [(ngModel)]="comment"
                name="comment"
                placeholder=""
                required
        ></textarea>
    </mat-form-field>
    <div style="margin-top: 30px"></div>
    <button mat-raised-button color="accent" type="submit" style="width: 50%">
        Submit
    </button>
    <button
            mat-raised-button
            (click)="cancel()"
            type="button"
            style="width: 45%; margin-left: 5px"
    >
        Cancel
    </button>
</form>
