<mat-toolbar class="toolbar" color="accent">
  <button mat-button routerLink="/">
    <mat-icon>map</mat-icon>
    DUMPSTERMAP
  </button>

  <button
    mat-button
    [routerLink]="'page/' + p.id"
    *ngFor="let p of staticPages.pages | async"
  >
    {{ p.title }}
  </button>
  <span style="flex: 1 1 auto"></span>
  <button class="desktop-only" mat-button [routerLink]="'search/'" matTooltip="Search and jump to a city">
    <mat-icon>search</mat-icon> Search
  </button>
  <button class="desktop-only" mat-stroked-button routerLink="/add"><mat-icon>add</mat-icon>Add Spot</button>
</mat-toolbar>

<div>
  <app-map></app-map>
  <div class="sidebar" *ngIf="sidebarService.isOpen | async">
    <router-outlet></router-outlet>
    <button class="sidebar-close-button" mat-flat-button color="accent" (click)="sidebarService.close()">
      Back to map
    </button>
  </div>
</div>
