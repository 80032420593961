import { Component, OnInit } from '@angular/core'
import { State } from '../../shared/state.service'
import { EmojiFinder } from '../emojis/emojis'
import { Spot } from '../spot.model'
import { Router } from '@angular/router'

@Component({
  selector: 'app-spot-detail',
  templateUrl: './spot-detail.component.html',
  styleUrls: ['./spot-detail.component.scss'],
})
export class SpotDetailComponent implements OnInit {
  spot: Spot
  emojis = ''
  showAddComment = true

  constructor(private state: State) {}

  ngOnInit(): void {
    this.state.selectedSpot.subscribe((spot) => {
      this.spot = spot
      this.emojis = this.extractEmojisFromSpot(this.spot)
    })

    this.state.routeChanged.subscribe(
      (route) => (this.showAddComment = !route.url.includes('/comment'))
    )
  }

  extractEmojisFromSpot(spot: Spot): string {
    let allComments = spot.properties.voting_set
      .map((comment) => comment.comment)
      .join()
    return EmojiFinder.getEmojiString(allComments)
  }
}
