import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { DUMPSTER_API } from '../../config'
import { ICommentCreate } from './comment.model'
import { Observable } from 'rxjs'

@Injectable()
export class CommentService {
  constructor(private http: HttpClient) {}

  create(comment: ICommentCreate): Observable<ICommentCreate> {
    return this.http.post<ICommentCreate>(`${DUMPSTER_API}/votings/`, comment)
  }
}
