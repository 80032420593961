import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { LatLng } from 'leaflet'
import { Observable } from 'rxjs'
import { GeoLocationService } from '../geolocation.service'
import { IPAPIResponse } from './ipapi.response'
import { map } from 'rxjs/operators'

/**
 * Retrieves the clients geolocation using https://ipapi.co/
 */
@Injectable()
export class IPAPIGeolocationService implements GeoLocationService {
  private API_URL = 'https://ipapi.co/json/'
  constructor(private http: HttpClient) {}
  public getLocation(): Observable<LatLng> {
    return this.http
      .get<IPAPIResponse>(this.API_URL)
      .pipe(
        map((response) => new LatLng(response.latitude, response.longitude))
      )
  }
}
