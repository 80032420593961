<mat-list>
  <mat-list-item>
    <img
      matListAvatar
      [src]="gravatarURL"
      alt="Avatar image"
      *ngIf="gravatarURL"
    />
    <div mat-line>
      {{ name }}
    </div>
    <div mat-line>{{ date | date }}</div>
  </mat-list-item>
</mat-list>
<p>{{ message }}</p>
