const dictionary = [
  ['🍞', ['bread', 'croissants', 'bakery']],
  ['🍎', ['fruit', 'apple']],
  ['🥕', ['vegetable', 'veggie', 'vegie']],
  ['📚', ['magazine', 'book']],
  ['🍼', ['milk', 'yoghurt', 'joghurt']],
  ['🧀', ['cheese']],
  ['🍌', ['banana']],
  ['🥚', ['egg']],
  ['🍭', ['candy', 'sweets', 'candies']],
  ['🍖', ['meat', 'sausage']],
  ['🍫', ['chocolate']],
  ['🌰', ['nuts']],
  ['🍪', ['cookies']],
]

export class EmojiFinder {
  public static listEmojis(value: string): string[] {
    if (!value) {
      return []
    }
    value = value.toLowerCase()

    let emojis = []

    for (let i = 0; i < dictionary.length; i++) {
      const keywords = dictionary[i][1]
      const emoji = dictionary[i][0]

      for (let y = 0; y < keywords.length; y++) {
        const keyword = keywords[y]
        if (value.includes(keyword)) {
          emojis.push(emoji)
        }
      }
    }
    return emojis
  }

  public static getEmojiString(text: string): string {
    return EmojiFinder.listEmojis(text).join('')
  }
}
