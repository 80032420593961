import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { SpotDetailComponent } from './spots/spot-detail/spot-detail.component'
import { SpotAddComponent } from './spots/spot-add/spot-add.component'
import { StaticPageComponent } from './static-pages/components/static-page/static-page.component'
import { CommentAddComponent } from './comments/comment-add/comment-add.component'
import { SearchComponent } from './search/search-sidebar/search.component'

const routes: Routes = [
  { path: '', redirectTo: 'page/about', pathMatch: 'full' },
  { path: 'page/:id', component: StaticPageComponent },
  {
    path: 'detail/:id',
    component: SpotDetailComponent,
    children: [{ path: 'comment', component: CommentAddComponent }],
  },
  { path: 'add', component: SpotAddComponent },
  { path: 'search', component: SearchComponent },
]

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class RoutingModule {}
