import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MatCardModule } from '@angular/material/card'
import { MatIconModule } from '@angular/material/icon'
import { MatToolbarModule } from '@angular/material/toolbar'
import { MatSidenavModule } from '@angular/material/sidenav'
import { MatListModule } from '@angular/material/list'
import { MatChipsModule } from '@angular/material/chips'
import { MatGridListModule } from '@angular/material/grid-list'
import { MatButtonModule } from '@angular/material/button'
import { MatInputModule } from '@angular/material/input'
import { MatSelectModule } from '@angular/material/select'
import { MatTooltipModule } from '@angular/material/tooltip'

const SHARED_MODULES = [
  MatCardModule,
  MatIconModule,
  MatToolbarModule,
  MatSidenavModule,
  MatCardModule,
  MatListModule,
  MatChipsModule,
  MatGridListModule,
  MatButtonModule,
  MatInputModule,
  MatSelectModule,
  MatTooltipModule,
  MatListModule,
]

@NgModule({
  declarations: [],
  imports: [CommonModule].concat(SHARED_MODULES),
  exports: SHARED_MODULES,
})
export class UIModule {}
