import { Component, OnDestroy, OnInit } from '@angular/core'
import { SpotService } from '../spot.service'
import { Spot, VotingValues } from '../spot.model'
import { State } from '../../shared/state.service'
import { Icon, LatLng, Marker } from 'leaflet'
import { Router } from '@angular/router'
import { SidebarService } from '../../shared/sidebar.service'
import { MapService } from '../../map/map.service'
import { GeocodingService } from '../../geocoding/geocoding.service'

@Component({
  selector: 'app-spot-add',
  templateUrl: './spot-add.component.html',
  styleUrls: ['./spot-add.component.scss'],
})
export class SpotAddComponent implements OnInit, OnDestroy {
  title: string
  rating: VotingValues
  comment: string

  marker: Marker

  latLng: LatLng
  adress: string

  constructor(
    private spots: SpotService,
    private state: State,
    private geocoding: GeocodingService,
    private router: Router,
    private sidebar: SidebarService,
    private map: MapService
  ) {}

  ngOnInit(): void {
    this.latLng = this.map.getCenter()
    this.marker = new Marker(this.latLng, {
      draggable: true,
      icon: new Icon({
        iconSize: [150, 150],
        iconUrl: 'assets/fadenkreuz.png',
      }),
    })
    this.map.addLayer(this.marker)
    this.marker.on({
      drag: (event) => (this.latLng = event.target.getLatLng()),
      dragstart: (event) => (this.adress = null),
      dragend: (event) => this.reverseGeocoding(),
    })

    this.sidebar.onClose.subscribe((_) => this.ngOnDestroy())
  }

  reverseGeocoding() {
    this.geocoding
      .reverse(this.marker.getLatLng())
      .subscribe((result) => (this.adress = result.displayName))
  }

  submit() {
    const spot: Spot = Spot.create(
      this.marker.getLatLng(),
      this.title,
      this.rating,
      this.comment
    )
    this.spots
      .create(spot)
      .subscribe((result) => this.state.setSelectedSpot(result))
  }

  cancel() {
    this.map.removeLayer(this.marker)
    this.router.navigate(['/'])
  }

  ngOnDestroy(): void {
    this.map.removeLayer(this.marker)
  }
}
